@tailwind base;
@tailwind components;
@tailwind utilities;
@layer {
    /* ANIMATIONS  ::START */

    /* Osnovni stil za sidebar */
    .sidebar {
        width: 96px;
        transition: 0.4s ease-in-out all;
    }

    /* Klasa koja pokazuje sidebar */
    .sidebar.open {
        width: 300px;
        animation: bounce 0.3s ease-in-out;
    }

    /* Bounce animacija */
    @keyframes bounce {
        0% {
            width: 300px;
        }
        50% {
            width: 345px;
        }
        75% {
            width: 265px;
        }
        100% {
            width: 300px;
        }
    }

    .sidebar.close {
        width: 96px;
        animation: bounce-close 0.3s ease-in-out;
    }

    @keyframes bounce-close {
        0% {
            width: 96px;
        }
        50% {
            width: 83px;
        }
        75% {
            width: 116px;
        }
        100% {
            width: 96px;
        }
    }



    .collapsible-content {
        overflow: hidden;
        transition: max-height 0.4s ease-in-out;
        max-height: 0;
    }


    .collapsible-content.open {
        max-height: 1000px;
    }


    .scale-in-animation {
        animation: scale-in 0.3s ease-in-out;
    }


    @keyframes scale-in {
        0% {
            opacity: 0;
            transform: scale(0.5);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

    .switch-animation-c1 {
        animation: switch-c1 0.8s ease-out infinite;
    }

    @keyframes switch-c1 {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(50%);
        }
        100% {
            transform: translateX(0);
        }
    }

    .switch-animation-c2 {
        animation: switch-c2 0.8s ease-out infinite;
    }

    @keyframes switch-c2 {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-50%);
        }
        100% {
            transform: translateX(0);
        }
    }

    .pulse-animation {
        animation: pulse 0.8s ease-out infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(1.1);
        }
        25% {
            transform: scale(0.8);
        }
        50% {
            transform: scale(.4);
        }
        75% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1.1);
        }
    }

    .z-1000 {
        z-index: 10000000;
    }


    .rotate-animation {
        animation-duration: 4s;
        animation-iteration-count: infinite;
        animation-name: rotate;
        animation-timing-function: linear;
    }


    @keyframes rotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    /* ANIMATIONS  ::END */

    .my-html-content {
        display: flex;
        justify-items: center;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
    }

    .my-html-content h1 {
        display: flex;
        justify-items: center;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
    }

    .my-html-content p {
        display: flex;
        justify-items: center;
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
    }


    ::-webkit-scrollbar{
        height: 6px;
        width: 6px;
        background-color: #088fcc15;
    }
    ::-webkit-scrollbar-thumb{
        border-radius: 6px;
        background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, theme('colors.base.500')),
        color-stop(0.72, theme('colors.base.600')),
        color-stop(0.86, theme('colors.base.700')));
    }


    ::-webkit-scrollbar-thumb:hover{
        background-color: #088fcc;
        background-image: none;
        cursor: pointer;
        transition: 0.3s linear all;
    }



    .number-selector-none input[type=number].number-to-text {
        -moz-appearance: textfield;
    }
    .number-selector-none input[type=number]::-webkit-inner-spin-button,
    .number-selector-none input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /** Chrome, Safari, Edge, Opera *!*/
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }



    .menu-items-up {
        bottom: 25px;
        transform-origin: bottom right;
    }

    .bg-blur {
        backdrop-filter: blur(20px);
    }

    .react-datepicker-popper {
        z-index: 100 !important;
    }

    .react-datepicker__close-icon {
        z-index: 100 !important;
        height: 100% !important;
        width: 32px !important;
    }

    .react-datepicker__close-icon::after {
        display: flex;
        justify-content: center;
        justify-items: center;
        height: 12px !important;
        width: 24px !important;
        font-size: 17px !important;
        font-weight: bold !important;
    }

    .react-datepicker__day--today {
        background-color: #2ba9e3 !important;
        color: white !important;
        border-radius: 6px 6px !important;
    }


    body {
        background-color: #F5F5F5;
        margin: 0;
        padding: 0;
    }

    .doughnut-chart {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
    }

    .inner-circle {
        position: absolute;
        width: 50%;
        height: 50%;
        top: 25%;
        left: 25%;
        background-color: #ffffff;
        border-radius: 50%;
        z-index: 1;
    }

    .label {
        position: absolute;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 0.75rem;
        pointer-events: none; /* Make sure text doesn't block hover events */
    }

}
